import { ethers } from 'ethers'
import { useState, useEffect } from 'react'
import { useEthereumContext } from '../ethereumContext/EthereumContext'
import { Box, Heading, Text, Button } from '@chakra-ui/react'
import PosterCloud from '../../abi/PosterCloud.json'
import { handleBuyTicket } from '../../utils/actions'
import Tickets from '../../abi/Tickets.json'
//15.01.2024
//const contractAddress="0x6319AC8f00aBfa58688728276fa87a7aD63E70ca";
//16.01.2024
//const contractAddress= "0xd44beB8E6F1a43B39bDAEAbEeaCdd1d80eC5Eedb";

//17.01.2024
//const contractAddress="0x4FFA5b7BBcFcfc2933b9bC1A142e1ca8d88C423d";
//const contractAddress="0x5fbdb2315678afecb367f032d93f642f64180aa3";
const contractAddress = "0x9cd0444E85922AcBE060A3ef6e4AD93Fc9b7Ab18";

export default function DisplayEvents() {
  const { account, provider, signer } = useEthereumContext()
  const [events, setEvents] = useState([])

  useEffect(() => {
    if (provider && signer) {
      fetchEvents()
    }
  }, [provider, signer])

  const fetchEvents = async () => {
    try {
      const contract = new ethers.Contract(contractAddress, PosterCloud.abi, signer);
      const readContract = new ethers.Contract(contractAddress, PosterCloud.abi,provider);
//const contractWithSigner = contract.connect(signer);
      const eventsCount = await contract.readEventsCount();
      const allEvents = []
      for (let i = 0; i < Number(eventsCount); i++) {
        console.log(i);
	let eventn=[];
        let event = await contract.getEventInfo(i);
		try {
//			const nfT = await contract.getTik(i,1);
//			//console.log("Tik: ",nfT);
			const toT = await contract.getTotal(i);
			//console.log("toT: ",toT);
			const cuR = await contract.getCurrentId(i);
			//console.log("cuR: ",cuR);
			//for (let j = 0; j < Number(cuR); j++) {
			let j=0;
				let tik =await contract.getTik(i,j);
				console.log("==>Tiks:",i,j,tik);
 				//console.log("==>Tik['1']:",i,j,tik['1']);
				 console.log("==>Tik[1]:",i,j,tik[1]);		
				event.map((c,i) => {
					if (i==2) eventn[i]=c.concat(" ",tik[1])
					else eventn[i]=c;
			 	});
				console.log("eventn",eventn);
			//}
	
			//console.log("===>nfT: ",nfT);
			//let index=0;
			//let event2=[...event];
		//	event.splice(index,0,nfT[3]);
		} catch (error) {
			eventn=[...event," "];
			console.log ("getTik error:", error);
		};
		console.log(eventn);
        allEvents.push(eventn)
      }

      setEvents(allEvents)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box>
      {events.map((event, index) => (
        <Box
          key={index}
          borderWidth={1}
          borderRadius='lg'
          overflow='hidden'
          p={4}
          mb={4}
        >
          <Heading mb={2}>{event[0]}</Heading>
          <Text mb={2}>{event[1]}</Text>
          <Text mb={2}>{event[2]}</Text>
          <Text mb={2}>{event[3]}</Text>
          <Button
            colorScheme='blue'
            onClick={() => handleBuyTicket(index, account)}
          >
            To buy a ticket
          </Button>
        </Box>
      ))}
    </Box>
  )
}
