import axios from 'axios'

export const addEvent = async (name, timestamp, info, totalTickets) => {
// für hardhat local server zunächst hostname -I => localhost=172.29.49.97   
  try {
   //const response = await axios.post(`http://172.29.49.97:5000/api/addevent`, {
   const response = await axios.post(`http://werlins.de/lwm/api/addevent`, {		
      name, timestamp, info, totalTickets,
    }, 
    {"headers": {
       'Content-Type': 'application/json', 
    }
   })
    alert(response.data.message)
  } catch (e) {
    alert(e.response.data.message)
  }
}

export const handleBuyTicket = async (eventId, addr) => {
  try {
   // const response = await axios.post(`http://172.29.49.97:5000/api/buytik`,{
    const response = await axios.post(`http://werlins.de/lwm/api/buytik`, {
      eventId,
      addr,
    },
    {"headers": {
       'Content-Type': 'application/json',
    }
  })
    alert(response.data.message)
  } catch (e) {
    alert(e.response.data.message)
  }
}

export const checkTicket = async (eventId, ticketId) => {
  try {
//localhost durch 172.29.49.97 ersetzt????

 // const response = await axios.post(`http://172.29.49.97:5000/api/check`, {
 const response = await axios.post(`http://werlins.de/lwm/api/check`, {
      eventId,
      ticketId,
    },
    {"headers": {
       'Content-Type': 'application/json',
    }
  })

    alert(response.data.message)
  } catch (e) {
    alert(e.response.data.message)
  }
}
